import React from "react";
import { jt } from "ttag";

import { IProduct } from "../../../models/catalogue.interfaces";
import { money } from "../../../utils/format";

import styles from "./index.module.scss";

interface IProps {
    productPrice: IProduct["price"];
    preCopy?: string;
}

export const DynamicPrice = (props: IProps): React.ReactNode => {
    const { productPrice } = props;
    if (productPrice.cosmetic_incl_tax !== productPrice.incl_tax) {
        const strikeThruPrice = (
            <s key="strikeThruPrice">{money(productPrice.incl_tax)}</s>
        );
        return (
            <div className={styles.priceWrapper}>
                <span
                    className={styles.strikeThruPrice}
                >{jt`${props.preCopy} ${strikeThruPrice}`}</span>
                <span className={styles.price}>
                    {money(productPrice.cosmetic_incl_tax)}
                </span>
            </div>
        );
    }
    return <div className={styles.price}>{money(productPrice.incl_tax)}</div>;
};
