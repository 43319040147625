import React from "react";

import { templates } from "@reactivated";

import { DynamicPrice } from "../apps/price/DynamicPrice";
import { IProduct } from "../models/catalogue.interfaces";

export const Template = (props: templates.DynamicPriceTemplate) => {
    if (!props.product) {
        return null;
    }
    const product: IProduct = JSON.parse(props.product);
    return <DynamicPrice productPrice={product.price} />;
};
