import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";
import { Key } from "ts-key-enum";
import { t } from "ttag";

import { VariantOptionBlockInputTypes } from "../../../constants";
import { IProduct } from "../../../models/catalogue.interfaces";
import { isoProductID } from "../../../models/nominals";
import { TDispatchMapper, TStateMapper } from "../../reducers.interfaces";
import { Dispatchers } from "../dispatchers";
import { rootProductSelector } from "../selectors";
import { getDefaultRootProduct } from "../utils";

interface IOwnProps {
    inputType?: string;
}

interface IReduxProps {
    rootProducts: IProduct[];
    selectedRootProduct: IProduct | null;
}

interface IDispatchProps {
    onSelectRootProduct: (product: IProduct) => void;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class PDPChangeRootProductBlockComponent extends React.Component<
    IProps,
    IState
> {
    private readonly optionRefs: (HTMLButtonElement | null)[] = [];

    private onOptionSelectorClick(
        product: IProduct,
        event: React.MouseEvent<HTMLElement>,
    ) {
        event.preventDefault();
        this.props.onSelectRootProduct(product);
    }

    private readonly onChange = (
        e: React.FormEvent<HTMLSelectElement | HTMLInputElement>,
    ) => {
        e.preventDefault();
        const rootProductID = isoProductID.wrap(
            parseInt(e.currentTarget.value, 10),
        );
        const rootProduct = this.props.rootProducts.find((p) => {
            return p.id === rootProductID;
        });
        if (rootProduct) {
            this.props.onSelectRootProduct(rootProduct);
            this.setState({
                rootProductTitle: rootProduct.title,
            });
        }
    };

    private onOptionSelectorKeyDown(
        index: number,
        event: React.KeyboardEvent<Element>,
    ) {
        const prevIndex = index - 1;
        const nextIndex = index + 1;

        const prevProduct = this.props.rootProducts[prevIndex];
        const nextProduct = this.props.rootProducts[nextIndex];

        const prevRef = this.optionRefs[prevIndex];
        const nextRef = this.optionRefs[nextIndex];

        // Left arrow
        if (event.key === Key.ArrowLeft && prevProduct && prevRef) {
            this.props.onSelectRootProduct(prevProduct);
            prevRef.focus();
        }

        // Right arrow
        if (event.key === Key.ArrowRight && nextProduct && nextRef) {
            this.props.onSelectRootProduct(nextProduct);
            nextRef.focus();
        }
    }

    private buildOptionSelector(product: IProduct, index: number) {
        const isSelected = product.id === this.props.selectedRootProduct?.id;
        const classes = classNames({
            "pdp-change-root-product-block__option": true,
            "pdp-change-root-product-block__option--selected": isSelected,
        });
        const tabControlID = `change-root-product-block-${product.id}`;
        const tabPanelID = `root-product-conditional-block-${product.id}`;
        return (
            <button
                key={`${product.id}`}
                ref={(ref) => {
                    this.optionRefs[index] = ref;
                }}
                id={tabControlID}
                role="tab"
                aria-selected={isSelected}
                aria-controls={tabPanelID}
                className={classes}
                onClick={this.onOptionSelectorClick.bind(this, product)}
                onKeyDown={this.onOptionSelectorKeyDown.bind(this, index)}
                tabIndex={isSelected ? 0 : -1}
            >
                {product.title}
            </button>
        );
    }

    render() {
        // If there's not multiple options for rootProduct (because, for example, we're on a single
        // product PDP), don't show the selector.
        if (this.props.rootProducts.length < 2) {
            return null;
        }
        if (this.props.inputType === VariantOptionBlockInputTypes.DROPDOWN) {
            const value = this.props.selectedRootProduct
                ? this.props.selectedRootProduct.id
                : getDefaultRootProduct(this.props.rootProducts).id;
            const htmlID = "rootProductModel";
            return (
                <div>
                    <label htmlFor={htmlID}>{t`Model`}</label>
                    <div className="product-option-dropdown">
                        <select
                            id={htmlID}
                            value={isoProductID.unwrap(value)}
                            onChange={this.onChange}
                        >
                            <optgroup label="Model">
                                {this.props.rootProducts.map((product) => {
                                    return (
                                        <option
                                            key={`${product.id}`}
                                            value={isoProductID.unwrap(
                                                product.id,
                                            )}
                                        >
                                            {product.title}
                                        </option>
                                    );
                                })}
                            </optgroup>
                        </select>
                    </div>
                </div>
            );
        }

        return (
            <nav role="tablist">
                {this.props.rootProducts.map((product, i) => {
                    return this.buildOptionSelector(product, i);
                })}
            </nav>
        );
    }
}

const mapStateToProps: TStateMapper<"configurator", IReduxProps, IOwnProps> = (
    state,
    ownProps,
) => {
    return {
        ...ownProps,
        rootProducts: state.configurator.entities.rootProducts,
        selectedRootProduct: rootProductSelector(state.configurator),
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        onSelectRootProduct: dispatchers.setSelectedRootProduct,
    };
};

export const PDPChangeRootProductBlock = connect(
    mapStateToProps,
    mapDispatchToProps,
)(PDPChangeRootProductBlockComponent);
