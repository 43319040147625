import React from "react";
import { connect } from "react-redux";

import { FormRadioSelect } from "../../../forms/FormRadioSelect";
import { IFormUUID } from "../../../models/nominals";
import { IWriteReviewTemplateField } from "../../../models/reviews.interfaces";
import { TDispatchMapper, TStateMapper } from "../../reducers.interfaces";
import { Dispatchers } from "../dispatchers";
import { getWriteReviewFormState } from "../selectors";

interface IOwnProps {
    formUUID: IFormUUID;
    name: string;
    field: IWriteReviewTemplateField;
}

interface IReduxProps {
    value: string;
    errors: string[];
    showErrors: boolean;
}

interface IDispatchProps {
    dispatchers: Dispatchers;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class WriteReviewFormRadioFieldComponent extends React.PureComponent<
    IProps,
    IState
> {
    private readonly onChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.props.dispatchers.onReviewFormRadioChange({
            formUUID: this.props.formUUID,
            fieldName: this.props.name,
            fieldValue: e.currentTarget.value,
            isChecked: e.currentTarget.checked,
        });
    };

    render() {
        const choices = this.props.field.options.map((option) => {
            return {
                value: option,
                label: option,
            };
        });
        return (
            <FormRadioSelect
                name={`${this.props.name}-${this.props.formUUID}`}
                id={`${this.props.name}-${this.props.formUUID}`}
                required={this.props.field.is_required}
                label={this.props.field.name}
                labelPlacement={"static"}
                legend="Recommendations"
                choices={choices}
                onChange={this.onChange}
                value={this.props.value}
                errors={this.props.errors}
                showErrorMessages={this.props.showErrors}
            />
        );
    }
}

const mapStateToProps: TStateMapper<"reviews", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const formState = getWriteReviewFormState(rootState.reviews, ownProps);
    return {
        value: formState?.valuesText[ownProps.name] ?? "",
        errors: formState.errors[ownProps.name] ?? [],
        showErrors: formState.showErrors,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        dispatchers: dispatchers,
    };
};

export const WriteReviewFormRadioField = connect(
    mapStateToProps,
    mapDispatchToProps,
)(WriteReviewFormRadioFieldComponent);
