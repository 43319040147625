import React from "react";
import { connect } from "react-redux";

import {
    IReviewsProductTypeID,
    isoReviewsProductID,
} from "../../../models/nominals";
import {
    IReviewQueryFacets,
    IReviewsProduct,
    ISearchFacet,
} from "../../../models/reviews.interfaces";
import { groupBy, localeSortBy, sortBy } from "../../../utils/functional";
import { TStateMapper } from "../../reducers.interfaces";
import { defaultState } from "../defaults";
import { IFacetCollection, ProductTypeFilter } from "./ProductTypeFilter";

const FACET_TYPE: IReviewQueryFacets = "product_id";

interface IProductType {
    id: IReviewsProductTypeID;
    name: string;
    sort_order: number;
    collections: IFacetCollection[];
}

interface IOwnProps {
    displayedProductTypeIDWhitelist: IReviewsProductTypeID[];
    isCarouselComponentType?: boolean;
    isCollapsedView?: boolean;
    isCondensedView?: boolean;
}

interface IReduxProps {
    products: IReviewsProduct[];
    productFacet: ISearchFacet | null;
}

interface IDispatchProps {}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class ProductTypeFilterSectionComponent extends React.PureComponent<
    IProps,
    IState
> {
    private *getProductTypeOptionCollections(
        productTypeID: IReviewsProductTypeID,
    ): Generator<IFacetCollection> {
        // Get all the products for the given type
        const products = this.props.products.filter(
            (product) => product.product_type === productTypeID,
        );
        const productIDs = products.map((product) => product.id);
        // Limit the product facet options to just products of the given type
        const allOptions = this.props.productFacet
            ? this.props.productFacet.options
            : [];
        const options = allOptions.filter((option) =>
            productIDs.includes(isoReviewsProductID.wrap(option.option_id)),
        );
        // Group the product options into collections, sorted by sort_order, then name.
        const collections = groupBy(
            sortBy(
                sortBy(localeSortBy(options, "group_name"), "group_id"),
                "group_sort_order",
            ),
            "group_id",
        );
        // Yield the non-empty collections
        for (const collection of collections) {
            if (collection.length > 0) {
                yield {
                    name: collection[0].group_name,
                    options: collection,
                };
            }
        }
    }

    private getProductTypes(): IProductType[] {
        // Build de-duplicated map of product type data
        const productTypeMap = new Map<IReviewsProductTypeID, IProductType>();
        for (const product of this.props.products) {
            if (
                !product.product_type ||
                (this.props.displayedProductTypeIDWhitelist.length > 0 &&
                    !this.props.displayedProductTypeIDWhitelist.includes(
                        product.product_type,
                    )) ||
                productTypeMap.has(product.product_type)
            ) {
                continue;
            }
            productTypeMap.set(product.product_type, {
                id: product.product_type,
                name: product.product_type_name || "",
                sort_order: product.product_type_sort_order,
                collections: [
                    ...this.getProductTypeOptionCollections(
                        product.product_type,
                    ),
                ],
            });
        }
        // Convert map into a sorted array
        const productTypes = sortBy([...productTypeMap.values()], "sort_order");
        return productTypes;
    }

    render() {
        const productTypes = this.getProductTypes();
        return (
            <div>
                {productTypes.map((productType) => (
                    <ProductTypeFilter
                        key={`${productType.id}`}
                        facetType={FACET_TYPE}
                        productTypeID={productType.id}
                        name={productType.name}
                        collections={productType.collections}
                        isCarouselComponentType={
                            !!this.props.isCarouselComponentType
                        }
                        isCollapsedView={this.props.isCollapsedView}
                        isCondensedView={this.props.isCondensedView}
                    />
                ))}
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"reviews", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.reviews || defaultState;
    return {
        products: state.data.products,
        productFacet:
            state.data.facets.find((facet) => facet.type === FACET_TYPE) ||
            null,
        ...ownProps,
    };
};

export const ProductTypeFilterSection = connect(mapStateToProps)(
    ProductTypeFilterSectionComponent,
);
