import React from "react";
import SVG from "react-inlinesvg";
import { BackendType } from "tsi-common-react/src/models/financing";
import { t } from "ttag";

import { interpolate } from "@thelabnyc/thelabui/src/utils/i18n";

import tempurpedicLogo from "../../../../../../sites/tsi-tempurpedic/client/src/svg/tempur-logo-full.svg";
import fortivaLogo from "../../../../img/finance/fortiva-logo.svg";
import { Link } from "../../../common/Link";
import { getLowestThresholdFortivaPlan } from "../../../utils/financing";
import { money } from "../../../utils/format";
import { getDinero } from "../../../utils/money";
import { urls } from "../../../utils/urls";

import styles from "./FortivaFinancingUpsellBlock.module.scss";

interface IProps {
    grandTotal: string;
}

export const FortivaFinancingUpsellBlock = (props: IProps) => {
    const grandTotal = getDinero(props.grandTotal);
    const lowestFortivaPlan = getLowestThresholdFortivaPlan(
        BackendType.FORTIVA,
    );
    if (!lowestFortivaPlan) {
        return null;
    }
    // Already meet plan threshold?
    if (grandTotal.greaterThanOrEqual(lowestFortivaPlan.threshold)) {
        return null;
    }
    const orderTotalDelta = lowestFortivaPlan.threshold.subtract(grandTotal);
    return (
        <div className={styles.root}>
            <div className={styles.logoContainer}>
                <SVG className={styles.fortivaLogo} src={fortivaLogo} />
                <SVG className={styles.tempurpedicLogo} src={tempurpedicLogo} />
            </div>
            <p className={styles.headline}>
                {interpolate(
                    t`Fortiva® Financing Available For Orders of %(threshold)s or more!`,
                    { threshold: money(lowestFortivaPlan.threshold) },
                )}
            </p>
            <p className={styles.copy}>
                {interpolate(
                    t`Please add %(threshold)s to your order to checkout with Fortiva® Retail Credit.`,
                    { threshold: money(orderTotalDelta) },
                )}
            </p>
            <p className={styles.link}>
                <Link
                    className="button"
                    href={urls.pageURL("home")}
                >{t`Continue Shopping`}</Link>
            </p>
        </div>
    );
};
