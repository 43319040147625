import React from "react";
import { connect } from "react-redux";
import { t } from "ttag";

import { ErrorBoundary } from "../../../common/ErrorBoundary";
import { ErrorModal } from "../../../common/ErrorModal";
import { LoadingSpinner } from "../../../common/LoadingSpinner";
import { config } from "../../../config";
import { Form } from "../../../forms";
import { CaptchaLibrary } from "../../../forms/Captcha";
import { strings } from "../../../localization";
import { IAddressCountry } from "../../../models/address.interfaces";
import { IBasket } from "../../../models/catalogue.interfaces";
import { IProductSKU } from "../../../models/nominals";
import { IUser } from "../../../models/user.interfaces";
import * as analytics from "../../../utils/analytics";
import { trackFormEvent } from "../../../utils/analytics";
import { papply } from "../../../utils/functional";
import { focusElement } from "../../../utils/keyboardFocus";
import { guardUnhandledAction } from "../../../utils/never";
import { urls } from "../../../utils/urls";
import { Dispatchers as CommonDispatchers } from "../../common/dispatchers";
import { Loaders as CommonLoaders } from "../../common/loaders";
import { TDispatchMapper, TStateMapper } from "../../reducers.interfaces";
import { Actions, ICheckoutHTTPError } from "../actions";
import {
    BillingAddress,
    IPreScreenModalProps,
} from "../checkout-steps/BillingAddress";
import { EmailAddress } from "../checkout-steps/EmailAddress";
import { PaymentMethods } from "../checkout-steps/PaymentMethods";
import { ShippingAddress } from "../checkout-steps/ShippingAddress";
import { ShippingMethod } from "../checkout-steps/ShippingMethod";
import { BasketSummary } from "../components/BasketSummary";
import { BasketVoucherForm } from "../components/BasketVoucherForm";
import { CSRHeader } from "../components/CSRHeader";
import { PaymentChildFrameListener } from "../components/PaymentChildFrameListener";
import {
    PredictedDeliveryDateDisplayType,
    PreferredDeliveryDateDisplayType,
    Step,
    StepIndexCheckoutFormNameMap,
} from "../constants";
import { defaults } from "../defaults";
import { Dispatchers } from "../dispatchers";
import { Loaders } from "../loaders";
import { IReduxState } from "../reducers.interfaces";
import { IErrorMsgTemplateContext, renderErrorMessageTemplate } from "../utils";

import styles from "./Checkout.module.scss";

type IStepEventHandler = (event?: React.MouseEvent<HTMLElement>) => void;

const stepNames: { [s in Step]: string } = {
    [Step.LOGIN]: "login",
    [Step.SHIPPING_ADDRESS]: "shipping-address",
    [Step.SHIPPING_METHOD]: "shipping-method",
    [Step.BILLING_ADDRESS]: "billing-method",
    [Step.PAYMENT_METHODS]: "payment-method",
    [Step.PLACING_ORDER]: "placing-order",
};

const isStep = (s: Step | string): s is Step => {
    return (s as string).substr === undefined;
};

interface IStepErrors {
    global?: string[];
    basket?: string[];
    shipping_address?: string[];
    shipping_method?: string[];
    payment_method?: string[];
    billing_address?: string[];
}

interface IOwnProps {
    formSteps?: Step[];
    getExtraSummaryContent: () => JSX.Element | null;
    getExtraSidebarContent: () => JSX.Element | null;
    buildFinancingUpsell: (grandTotal: string) => JSX.Element | null;
    buildFortivaFinancingUpsell?: (grandTotal: string) => JSX.Element | null;
    showFinancingPreQual?: boolean;
    buildPreScreenModal?: (props: IPreScreenModalProps) => JSX.Element | null;
    enableSplitPay?: boolean;
    disableFreeShippingMessage?: boolean;
    predictedDeliveryDates?: PredictedDeliveryDateDisplayType;
    preferredDeliveryDates?: PreferredDeliveryDateDisplayType;
    preferredDeliveryContent?: React.ReactNode;
}

interface IReduxProps extends IReduxState {
    user: IUser | null;
}

interface IDispatchProps {
    commonLoaders: CommonLoaders;
    loaders: Loaders;
    dispatchers: Dispatchers;
    actions: Actions;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {
    isLoading: boolean;
    isStickyActive: boolean;
    messages: IStepErrors;
}

class CheckoutComponent extends React.Component<IProps, IState> {
    public TABLET_WIDTH_THRESHOLD = 769;
    private readonly containerRef = React.createRef<HTMLDivElement>();
    private readonly basketRef = React.createRef<HTMLDivElement>();
    private readonly formRef = React.createRef<Form>();

    public state: IState = {
        isStickyActive: true,
        isLoading: true,
        messages: {},
    };

    private readonly saveEmailAddress = () => {
        return this.props.actions.saveEmailAddress(this.props);
    };

    private readonly saveShippingAddress = (bypassUSPSValidation: boolean) => {
        return this.props.actions.saveShippingAddress(
            this.props,
            bypassUSPSValidation,
        );
    };

    private readonly saveBillingAddress = (bypassUSPSValidation: boolean) => {
        return this.props.actions.saveBillingAddress(
            this.props,
            bypassUSPSValidation,
        );
    };

    private readonly saveShippingMethod = () => {
        return this.props.actions.saveShippingMethod(this.props);
    };

    private readonly savePreferredDeliveryDate = (
        preferredDate: Date | null,
    ) => {
        return this.props.actions.savePreferredDeliveryDate(
            preferredDate,
            this.props,
        );
    };

    private readonly trackPageView = (basket: IBasket) => {
        const skus = basket.lines.reduce<IProductSKU[]>(
            (skuList, line) => skuList.concat(line.product.skus),
            [],
        );
        const price = basket.total_excl_tax;
        analytics.trackProductValuePageView(
            skus,
            price,
            "cart",
            "CartPageView",
        );
        analytics.trackBasketEvent("begin_checkout", basket);
    };

    private isTabletOrSmallerWidth() {
        return window.innerWidth < this.TABLET_WIDTH_THRESHOLD;
    }

    private checkStickyState() {
        this.setState({
            isStickyActive: !this.isTabletOrSmallerWidth(),
        });
    }

    async componentDidMount() {
        // Send loading event to GA
        this.sendAnalyticsData("loading");

        // Setup resize event handlers for sticky functionality
        this.checkStickyState();
        window.addEventListener("resize", () => {
            this.checkStickyState();
        });

        // Load all the data necessary to begin checkout
        type PreLoadData = [
            Promise<IBasket>,
            Promise<IUser>,
            Promise<IAddressCountry[]>,
            Promise<void>,
            Promise<string[]>,
        ];
        const loading: PreLoadData = [
            this.props.loaders.loadBasket(),
            this.props.commonLoaders.loadCurrentUser(),
            this.props.loaders.loadCountries(),
            this.props.loaders.loadShippingAndBillingAddresses(),
            this.props.loaders.loadPaymentMethods(),
        ];
        const [basket, user] = await Promise.all(loading);

        // Redirect home if the user doesn't have any lines in their basket
        if (basket.lines.length <= 0) {
            urls.navigateTo("home");
            throw new Error("Cannot checkout with empty basket");
        }

        // Load assisted user if the active user is a CSR
        if (user.is_csr) {
            try {
                await this.props.loaders.loadAssistedUser();
            } catch (e) {
                console.warn(e);
            }
        }

        // Load the email address associated with the basket. Has to be deliberately
        // after the call to loadAssistedUser to prevent a race-condition.
        await Promise.all([
            this.props.loaders.loadEmailAddress(),
            this.props.loaders.loadUserAddresses(),
        ]);

        // Loading is complete
        this.sendAnalyticsData(this.props.form.current_step);
        this.setState({
            isLoading: false,
        });

        this.trackPageView(basket);
    }

    componentDidUpdate() {
        // Clear max-height for mobile and tablet
        if (
            !this.state.isStickyActive &&
            this.basketRef.current &&
            this.containerRef.current
        ) {
            this.containerRef.current.style.maxHeight = "unset";
        }
        // For sticky functionality, calculate current basket height
        // And apply to max-height of the container in laptop
        if (
            this.state.isStickyActive &&
            this.basketRef.current &&
            this.containerRef.current
        ) {
            const childHeight = this.basketRef.current.clientHeight;
            this.containerRef.current.style.maxHeight = `${childHeight}px`;
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.checkStickyState);
    }

    private readonly onCheckoutError = (err: ICheckoutHTTPError) => {
        console.error(err);

        const errors = (err && err.response ? err.response.body : {}) || {};
        let step: Step = Step.SHIPPING_ADDRESS;
        const messages: IStepErrors = {};
        const ctx: IErrorMsgTemplateContext = {
            basketID: this.props.data.basket
                ? this.props.data.basket.encoded_basket_id
                : undefined,
            orderID: this.props.data.order
                ? this.props.data.order.number
                : undefined,
        };
        if (errors.global) {
            step = Step.PAYMENT_METHODS;
            this.props.dispatchers.updatePaymentError(errors.global[0]);
        } else if (errors.basket) {
            step = Step.SHIPPING_ADDRESS;
            messages.basket = errors.basket;
        } else if (errors.shipping_address) {
            step = Step.SHIPPING_ADDRESS;
            messages.shipping_address = [
                renderErrorMessageTemplate(
                    strings.get("CHECKOUT_ERROR_SHIPPING_ADDRESS") || "",
                    ctx,
                ),
            ].concat(errors.shipping_address);
        } else if (errors.shipping_method_code) {
            step = Step.SHIPPING_METHOD;
            messages.shipping_method = [
                renderErrorMessageTemplate(
                    strings.get("CHECKOUT_ERROR_SHIPPING_METHOD") || "",
                    ctx,
                ),
            ].concat(errors.shipping_method_code);
        } else if (errors.payment) {
            step = Step.PAYMENT_METHODS;
            messages.payment_method = [
                renderErrorMessageTemplate(
                    strings.get("CHECKOUT_ERROR_PAYMENT_METHOD") || "",
                    ctx,
                ),
            ].concat(errors.payment);
        } else if (errors.billing_address) {
            step = Step.BILLING_ADDRESS;
            messages.billing_address = [
                renderErrorMessageTemplate(
                    strings.get("CHECKOUT_ERROR_BILLING_ADDRESS") || "",
                    ctx,
                ),
            ].concat(errors.billing_address);
        } else {
            step = Step.SHIPPING_ADDRESS;
            messages.shipping_address = [
                renderErrorMessageTemplate(
                    strings.get("CHECKOUT_ERROR_FALLBACK") || "",
                    ctx,
                ),
            ];
        }

        // Go to the first step with an error. Then, on the next tick, show the error message, and scroll the page up to view it.
        this.scrollToFirstErrorMessage();
        this.gotoStep(step);
        setTimeout(() => {
            this.setState({ messages: messages }, () => {
                this.scrollToFirstErrorMessage();
            });
        }, 0);
    };

    private scrollToFirstErrorMessage() {
        const setScrollTop = (px: number) => {
            document.body.scrollTop = px;
            if (document.documentElement) {
                document.documentElement.scrollTop = px;
            }
        };
        const error = document.querySelector(".form__error");
        if (!error) {
            setScrollTop(0);
            return;
        }
        error.scrollIntoView(true);
        setScrollTop(Math.max(0, document.body.scrollTop - 80));
    }

    private sendAnalyticsData(step: Step | string) {
        const stepName = isStep(step) ? stepNames[step] : step;
        analytics.trackVirtualSubPage(stepName);
        if (this.formRef.current?.formElem) {
            trackFormEvent(
                "form_submit",
                StepIndexCheckoutFormNameMap[this.props.form.current_step],
                this.formRef.current.formElem,
                this.props.data.basket,
            );
        }
    }

    private focusStep(step: Step) {
        let stepName = isStep(step) ? stepNames[step] : step;
        if (stepName === "billing-method") {
            stepName = "billing-address";
        }
        const focusClass = ".checkout-step--" + stepName;
        focusElement(focusClass);
    }

    private gotoStep(nextStep: Step) {
        this.sendAnalyticsData(nextStep);
        this.focusStep(nextStep);
        this.props.dispatchers.changeFormField({
            current_step: nextStep,
        });
    }

    private renderStepLogin(
        onContinue: IStepEventHandler,
        onEdit: IStepEventHandler,
    ) {
        // Only display the email step if the user already has an email address. Otherwise,
        // it becomes part of the shipping address step.
        let emailStep: JSX.Element | null = null;
        if (
            this.props.user &&
            this.props.user.email &&
            !this.props.user.is_csr
        ) {
            emailStep = (
                <EmailAddress
                    key={Step.LOGIN}
                    user={this.props.user}
                    onContinue={onContinue}
                    onEdit={onEdit}
                />
            );
        }
        return emailStep;
    }

    private renderStepShippingAddress(
        idx: number,
        onContinue: IStepEventHandler,
        onEdit: IStepEventHandler,
    ) {
        const isCurrentStep =
            this.props.form.current_step === Step.SHIPPING_ADDRESS;
        return (
            <ShippingAddress
                key={Step.SHIPPING_ADDRESS}
                heading={`${idx}. ${strings.get(
                    "CHECKOUT_SECTION_TITLE_SHIPPING_ADDRESS",
                )}`}
                errors={this.state.messages.shipping_address || []}
                saveEmailAddress={this.saveEmailAddress}
                saveShippingAddress={this.saveShippingAddress}
                onContinue={onContinue}
                onEdit={onEdit}
                isCurrentStep={isCurrentStep}
                formRef={isCurrentStep ? this.formRef : undefined}
            />
        );
    }

    private renderStepShippingMethod(
        idx: number,
        onContinue: IStepEventHandler,
        onEdit: IStepEventHandler,
    ) {
        const isCurrentStep =
            this.props.form.current_step === Step.SHIPPING_METHOD;
        return (
            <ShippingMethod
                key={Step.SHIPPING_METHOD}
                heading={`${idx}. ${strings.get(
                    "CHECKOUT_SECTION_TITLE_SHIPPING_METHOD",
                )}`}
                errors={this.state.messages.shipping_method || []}
                predictedDeliveryDates={
                    this.props.predictedDeliveryDates ||
                    PredictedDeliveryDateDisplayType.DISABLED
                }
                preferredDeliveryDates={
                    this.props.preferredDeliveryDates ||
                    PreferredDeliveryDateDisplayType.DISABLED
                }
                preferredDeliveryContent={this.props.preferredDeliveryContent}
                saveShippingMethod={this.saveShippingMethod}
                savePreferredDeliveryDate={this.savePreferredDeliveryDate}
                onContinue={onContinue}
                onEdit={onEdit}
                isCurrentStep={isCurrentStep}
                formRef={isCurrentStep ? this.formRef : undefined}
            />
        );
    }

    private renderStepBillingAddress(
        idx: number,
        onContinue: IStepEventHandler,
        onEdit: IStepEventHandler,
    ) {
        const isCurrentStep =
            this.props.form.current_step === Step.BILLING_ADDRESS;
        return (
            <BillingAddress
                key={Step.BILLING_ADDRESS}
                heading={`${idx}. ${strings.get(
                    "CHECKOUT_SECTION_TITLE_BILLING_ADDRESS",
                )}`}
                errors={this.state.messages.billing_address || []}
                showFinancingPreQual={this.props.showFinancingPreQual}
                buildPreScreenModal={this.props.buildPreScreenModal}
                saveBillingAddress={this.saveBillingAddress}
                onContinue={onContinue}
                onEdit={onEdit}
                isCurrentStep={isCurrentStep}
                formRef={isCurrentStep ? this.formRef : undefined}
            />
        );
    }

    private renderStepPaymentMethod(
        idx: number,
        onContinue: IStepEventHandler,
    ) {
        const submitOrder = () => {
            onContinue();
            this.props.actions
                .placeOrder(this.props.user, this.props)
                .catch((err) => {
                    this.onCheckoutError(err);
                });
        };
        const isCSR = this.props.user && this.props.user.is_csr;
        const isCurrentStep =
            this.props.form.current_step === Step.PAYMENT_METHODS;
        return (
            <PaymentMethods
                key={Step.PAYMENT_METHODS}
                heading={`${idx}. ${strings.get(
                    "CHECKOUT_SECTION_TITLE_PAYMENT_METHODS",
                )}`}
                errors={this.state.messages.payment_method || []}
                buildFinancingUpsell={this.props.buildFinancingUpsell}
                buildFortivaFinancingUpsell={
                    this.props.buildFortivaFinancingUpsell
                }
                enableSplitPay={this.props.enableSplitPay ? true : false}
                isCSR={!!isCSR}
                onContinue={submitOrder}
                isCurrentStep={isCurrentStep}
                formRef={isCurrentStep ? this.formRef : undefined}
            />
        );
    }

    private renderStep(
        idx: number,
        step: Step,
        onContinue: IStepEventHandler,
        onEdit: IStepEventHandler,
    ) {
        switch (step) {
            case Step.LOGIN:
                return this.renderStepLogin(onContinue, onEdit);
            case Step.SHIPPING_ADDRESS:
                return this.renderStepShippingAddress(idx, onContinue, onEdit);
            case Step.SHIPPING_METHOD:
                return this.renderStepShippingMethod(idx, onContinue, onEdit);
            case Step.BILLING_ADDRESS:
                return this.renderStepBillingAddress(idx, onContinue, onEdit);
            case Step.PAYMENT_METHODS:
                return this.renderStepPaymentMethod(idx, onContinue);
            case Step.PLACING_ORDER:
                return null;
            default:
                guardUnhandledAction(step);
        }
        return null;
    }

    private renderSteps() {
        if (!this.props.data.basket) {
            return null;
        }

        const gotoStep = (
            nextStep: Step,
            event: React.MouseEvent<HTMLElement> | undefined,
        ) => {
            if (event) {
                event.preventDefault();
            }
            this.gotoStep(nextStep);
        };

        // Determine what steps to show in the checkout process.
        const formSteps = this.props.formSteps || [
            Step.LOGIN,
            Step.SHIPPING_ADDRESS,
            Step.SHIPPING_METHOD,
            Step.BILLING_ADDRESS,
            Step.PAYMENT_METHODS,
            Step.PLACING_ORDER,
        ];
        const stepElems = formSteps.map((step, idx) => {
            const nextStep: Step | undefined = formSteps[idx + 1];
            const onContinue = papply(gotoStep, nextStep);
            const onEdit = papply(gotoStep, step);
            return this.renderStep(idx, step, onContinue, onEdit);
        });
        return (
            <div className="">
                <CSRHeader user={this.props.user} />
                {stepElems}
            </div>
        );
    }

    private renderBasket() {
        return (
            <div className={styles.stickyContainer} ref={this.containerRef}>
                <div className="basket-summary" ref={this.basketRef}>
                    <h2 className="basket-summary__header">
                        {t`Order Summary`}
                    </h2>
                    <BasketSummary
                        data={this.props.data}
                        derived={this.props.derived}
                        removeVoucherCode={this.props.actions.removeVoucherCode}
                        disableFreeShippingMessage={
                            this.props.disableFreeShippingMessage
                        }
                        getExtraContent={this.props.getExtraSummaryContent}
                    />
                    <div className="basket-summary__promocode">
                        <BasketVoucherForm
                            addVoucherCode={this.props.actions.addVoucherCode}
                        />
                    </div>
                    <ErrorBoundary>
                        {this.props.getExtraSidebarContent()}
                    </ErrorBoundary>
                </div>
            </div>
        );
    }

    private renderBasketErrorModal() {
        const errorModalIsOpen = !!(
            this.state.messages.basket && this.state.messages.basket.length > 0
        );
        const redirectToBasket = (
            e: React.FormEvent<Element> | React.KeyboardEvent<Element>,
        ) => {
            e.preventDefault();
            urls.navigateTo("basket-summary");
        };
        return (
            <ErrorModal
                name="checkout"
                isOpen={errorModalIsOpen}
                onRequestClose={redirectToBasket}
                closeButtonText="Edit Cart"
            >
                <p>An error occurred with your order.</p>
                <ul>
                    {(this.state.messages.basket || []).map((msg, i) => {
                        return <li key={i}>{msg}</li>;
                    })}
                </ul>
            </ErrorModal>
        );
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div className={styles.grid}>
                    <LoadingSpinner />
                </div>
            );
        }
        return (
            <div className={styles.grid}>
                {config.get("ENABLE_CHECKOUT_CAPTCHA") && <CaptchaLibrary />}
                {this.renderSteps()}
                {this.renderBasket()}
                {this.renderBasketErrorModal()}
                <PaymentChildFrameListener
                    onCheckoutError={this.onCheckoutError}
                    hasPaymentMethodError={!!this.state.messages.payment_method}
                />
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"checkout", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.checkout || defaults;
    return {
        user: rootState.common.user,
        ...state,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const commonDispatchers = new CommonDispatchers(dispatch);
    const commonLoaders = new CommonLoaders(commonDispatchers);
    const dispatchers = new Dispatchers(dispatch);
    const loaders = new Loaders(dispatchers);
    const actions = new Actions(loaders, dispatchers);
    return {
        commonLoaders: commonLoaders,
        dispatchers: dispatchers,
        loaders: loaders,
        actions: actions,
    };
};

export const Checkout = connect(
    mapStateToProps,
    mapDispatchToProps,
)(CheckoutComponent);
