import React from "react";

import { Disposable } from "../../../utils/events";
import * as signals from "../../signals";
import { Dispatchers } from "../dispatchers";
import { IFinancingPayment } from "../reducers.interfaces";
import {
    getFinancingData,
    getLongestFinancingPlan,
    isFortivaAcctNum,
} from "../utils";
import { IPaymentMethodFormProps } from "./PaymentMethod.interfaces";
import { AddAccountForm } from "./PaymentMethod_Financing_AddAccountForm";
import { FinancingDisabledWarning } from "./PaymentMethod_Financing_Disabled";

interface IProps extends IPaymentMethodFormProps {
    buildFinancingUpsell: (grandTotal: string) => JSX.Element | null;
    buildFortivaFinancingUpsell?: (grandTotal: string) => JSX.Element | null;
    setPaymentMethodFields: Dispatchers["setPaymentMethodFields"];
    resetPaymentMethods: Dispatchers["resetPaymentMethods"];
    changeFinancingAccountNumber: Dispatchers["changeFinancingAccountNumber"];
    changeAmount: Dispatchers["changeAmount"];
    onApplyDenial: () => void;
    addSplitPayToFinancing: () => void;
    enableSplitPay: boolean;
}

interface IState {}

export class Financing extends React.Component<IProps, IState> {
    private handler_onApproval: Disposable | null = null;
    private handler_onDenial: Disposable | null = null;

    private readonly onValidStateChange = (
        fieldName: string,
        errorMessages: string[],
    ) => {
        this.props.onValidStateChange(
            fieldName,
            errorMessages,
            this.props.methodKey,
        );
    };

    componentDidMount() {
        this.handler_onApproval = signals.financing.onApproval.on((account) => {
            // Select the new account
            this.props.setPaymentMethodFields(this.props.methodKey, {
                new_financing_account: account,
                financing_account: account.account_number,
            });
        });
        this.handler_onDenial = signals.financing.onDenial.on(() => {
            this.props.onApplyDenial();
        });
    }

    componentWillUnmount() {
        if (this.handler_onApproval) {
            this.handler_onApproval.dispose();
        }
        if (this.handler_onDenial) {
            this.handler_onDenial.dispose();
        }
    }

    private getMethodData(): IFinancingPayment {
        const data = this.props.form.payment_methods[this.props.methodKey];
        let wellsFargoData: IFinancingPayment | null = null;
        if (data.method_type === "financing") {
            wellsFargoData = data;
        }
        const baseFields: IFinancingPayment = {
            method_type: "financing",
            amount: "",
            pay_balance: false,
            new_financing_account: null,
            financing_account: "",
            financing_plan: null,
            has_agreed: false,
            has_esigned: false,
        };
        return {
            ...baseFields,
            ...wellsFargoData,
        };
    }

    private buildFinancingCTA() {
        if (!this.props.data.basket || !this.props.derived.grand_total) {
            return null;
        }
        const methodData = this.getMethodData();
        const switchToCreditCard = () => {
            this.props.resetPaymentMethods(this.props.data.payment_methods);
        };
        // Figure out if credit applications are allowed
        const allowCreditApplications =
            this.props.data.financing_plans.reduce<boolean>((memo, plan) => {
                return memo || plan.allow_credit_application;
            }, false);
        // Add a pre-existing Wells Fargo account by it's account number
        return (
            <div className="financing-form__cta financing-form__cta--add-account">
                <AddAccountForm
                    header={this.props.header}
                    methodKey={this.props.methodKey}
                    countries={this.props.data.countries}
                    billing_states={this.props.data.billing_states}
                    savedAddresses={this.props.data.addresses}
                    form={this.props.form}
                    errors={this.props.errors}
                    setPaymentMethodFields={this.props.setPaymentMethodFields}
                    changeFinancingAccountNumber={
                        this.props.changeFinancingAccountNumber
                    }
                    changeAmount={this.props.changeAmount}
                    onValidStateChange={this.onValidStateChange}
                    showErrorMessages={this.props.showErrorMessages}
                    plans={this.props.data.financing_plans}
                    grandTotal={this.props.derived.grand_total}
                    allowCreditApplications={allowCreditApplications}
                    switchToCreditCard={switchToCreditCard}
                    newAccount={methodData.new_financing_account || undefined}
                    basketTotal={parseFloat(this.props.derived.grand_total)}
                    cartID={this.props.data.basket.encoded_basket_id}
                    isSplitPay={this.props.isSplitPay}
                    shouldFreezeAmount={this.props.shouldFreezeAmount}
                    disabled={this.props.disabled}
                    derived={this.props.derived}
                    addSplitPayToFinancing={this.props.addSplitPayToFinancing}
                    enableSplitPay={this.props.enableSplitPay}
                />
            </div>
        );
    }

    private buildFinancingBlock() {
        let upsell: JSX.Element | null = null;
        const data = getFinancingData(this.props);
        if (this.props.derived.grand_total) {
            // Display Fortiva Upsell If Fortiva Account has authenticated
            if (
                this.props.buildFortivaFinancingUpsell &&
                data &&
                isFortivaAcctNum(data.financing_account)
            ) {
                upsell = this.props.buildFortivaFinancingUpsell(
                    this.props.derived.grand_total,
                );
            } else {
                upsell = this.props.buildFinancingUpsell(
                    this.props.derived.grand_total,
                );
            }
        }
        return (
            <div className="financing-form">
                {upsell}
                {this.buildFinancingCTA()}
            </div>
        );
    }

    render() {
        // Financing is disabled if no financing plans were returned
        const longestActivePlan = getLongestFinancingPlan(
            this.props.data.financing_plans,
        );
        // Financing might be completely disabled
        if (!longestActivePlan) {
            return (
                <div className="checkout-step__payment-form">
                    <FinancingDisabledWarning />
                </div>
            );
        }
        // Standard behavior for when financing is enabled
        return (
            <div className="checkout-step__payment-form">
                {this.buildFinancingBlock()}
            </div>
        );
    }
}
